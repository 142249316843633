'use client';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

import NotFoundPage from '@/components/common/404';
import GlobalLoader from '@/components/common/GlobalLoader';
import { getSavedLogin } from '@/utils/functions/loginUtils';
import { routePaths } from '@/utils/routePaths';

export default function NotFound() {
  const [ready, setReady] = useState(false);
  const { push } = useRouter();

  useEffect(() => {
    const login = getSavedLogin();
    if (login) {
      push(
        login === 'OWNER'
          ? routePaths.owner.notFound
          : routePaths.customer.notFound,
      );
    } else {
      setReady(true);
    }
  }, [push, setReady]);

  return ready ? <NotFoundPage login /> : <GlobalLoader open />;
}
