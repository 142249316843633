import MuiBackdrop, { BackdropProps } from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import React, { FC } from 'react';

const Backdrop = styled(MuiBackdrop)(() => ({
  zIndex: 99999,
  backdropFilter: 'blur(10px)',
  backgroundColor: '#09093299',
}));

const GlobalLoader: FC<BackdropProps> = (props) => {
  return props.open ? (
    <Backdrop {...props} data-testid="globalLoader" open>
      <CircularProgress sx={{ color: '#ffffff' }} />
    </Backdrop>
  ) : null;
};

export default GlobalLoader;
