import Smartlook from 'smartlook-client';

let isSmartlookInitialized = false;

export const initSmartlook = (projectKey?: string) => {
  if (typeof window !== 'undefined' && !isSmartlookInitialized && projectKey) {
    Smartlook.init(projectKey);
    isSmartlookInitialized = true;
  }
};

export const identifyUser = (
  userId: string,
  userInfo: Record<string, string | number>,
) => {
  if (typeof window !== 'undefined' && isSmartlookInitialized) {
    Smartlook.identify(userId, userInfo);
  }
};

export const anonymizeUser = () => {
  if (typeof window !== 'undefined' && isSmartlookInitialized) {
    Smartlook.anonymize();
  }
};
