import { deleteUserAttributes, signOut } from '@aws-amplify/auth';

import { ADMIN_SESSION_ACTIVE } from '@/constants/values';
import { anonymizeUser } from '@/smartlook';
import { USER_TYPE } from '@/utils/types';

const STORAGE_KEY = 'login-type';
export const saveLogin = (type: USER_TYPE) =>
  localStorage.setItem(STORAGE_KEY, type);

export const getSavedLogin = () => localStorage.getItem(STORAGE_KEY);

export const clearSavedLogin = () => localStorage.removeItem(STORAGE_KEY);

export const logOut = async () => {
  if (localStorage.getItem(ADMIN_SESSION_ACTIVE)) {
    await deleteUserAttributes({ userAttributeKeys: ['custom:impersonate'] });
    localStorage.clear();
    sessionStorage.clear();
    window.close();
    return;
  }
  try {
    await signOut();
    clearSavedLogin();
  } catch {
    localStorage.clear();
    sessionStorage.clear();
    window.location.pathname = '/login';
  }
  anonymizeUser();
};
